import React from "react"
import Logo from "images/logo-primary.png"

export default function MemeberCard({
  avatar,
  name,
  speciality,
  role,
  onClick,
}) {
  return (
    <div className="group" onClick={onClick}>
      <div className="relative overflow-hidden rounded-lg border border-gray-50 transition duration-300 cursor-pointer lg:hover:scale-105 ">
        <div
          className="bg-gray-50"
          style={{
            paddingBottom: "120%",
          }}
        ></div>
        {avatar ? (
          avatar
        ) : (
          <div className="inset-0 w-full absolute h-full flex items-center justify-center">
            <img src={Logo} className="px-8 lg:px-16" />
          </div>
        )}
      </div>
      <div className="py-3 text-center">
        <h4 className="text-gray-500 line-clamp-1">{role || "직책"}</h4>
        <h3 className="lg:text-lg text-gray-800 font-medium line-clamp-1">
          {name || "홍길동"}
        </h3>
        {speciality && (
          <h4 className="text-primary line-clamp-1">
            {speciality || "전문분야"}
          </h4>
        )}
      </div>
    </div>
  )
}
